<template>
    <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('stat.statFor') + ' ' + monthYear }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <b-overlay :show="showOverlay">
          <template v-if="downloadStat.length > 0">
            <b-row class="mb-3 pb-3 statTable mx-3" v-for="(ds, index) in downloadStat" :key="ds.id">
              <b-col cols="1" class="text-right">
                {{ index + 1 }}.
              </b-col>
              <b-col cols="4">
                <b-link :to="{ name : 'EditUser', params: { username: ds.user.email }}" target="_blank">
                {{ ds.user.contactPerson }} ({{ ds.user.enterpriseShortName | removeLat}})
                </b-link>
              </b-col>
              <b-col cols="4">
                <template v-if="ds.media.mimeType">
                  <b-link v-if="ds.media.mimeType.indexOf('image') >= 0" :to="{ name: 'Image', params : { lang: $root.lang.shortName, imageId: ds.media.id } }" target="_blank">
                    {{ ds.media.title }}
                  </b-link>

                  <b-link v-if="ds.media.mimeType.indexOf('video') >= 0" :to="{ name: 'Video', params : { lang: $root.lang.shortName, videoId: ds.media.id } }" target="_blank">
                    {{ ds.media.title }}
                  </b-link>

                 <b-link v-if="ds.media.mimeType.indexOf('audio') >= 0" :to="{ name: 'Audio', params : { lang: $root.lang.shortName, audioId: ds.media.id } }" target="_blank">
                    {{ ds.media.title }}
                  </b-link>

                  
                </template>

                <template v-else>
                 {{ ds.media.title }}  <!-- <strong>Unknown type</strong> -->
                </template>

              </b-col>
              <b-col cols="3" class="text-right">
                {{ dateTime(ds.downloadedAt, 'medium') }}
              </b-col>
            </b-row>
            </template>
            <template v-else>
              <b-row>
                <b-col cols="12" class="mx-4">
                  {{ $t('stat.noData') }}
                </b-col>
              </b-row>
            </template>

          <b-row class="mx-2 mt-5">
            <b-col class="d-flex justify-content-between">
              <b-button variant="primary" @click="loadPrevMonth">{{ $t('general.prevMonth') }}</b-button>
              <b-button variant="primary" @click="loadNextMonth">{{ $t('general.nextMonth') }}</b-button>
            </b-col>
          </b-row>
          </b-overlay>
        </b-col>
      </b-row>
    </b-container> 
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
export default {
  name : "DownloadStatistic",
  components: {NavBar},
  data : function() {
    return {
      downloadStat : {},
      month : new Date().getMonth() + 1,
      year : new Date().getFullYear(),
      showOverlay: false
    }
  },

  computed :  {
    monthYear : function() {
      return this.month + "-" + this.year;
    }
  },

  created: function() {
    this.loadData();
  },

  methods: {
    loadPrevMonth: function() {
      this.month = this.month - 1;
      if (this.month === 0) {
        this.year--;
        this.month = 12;
      }
      this.loadData();
    },
    loadNextMonth: function() {
      this.month = this.month + 1;
      if (this.month === 13) {
        this.year++;
        this.month = 1;
      }
      this.loadData();
    },
    async loadData() {
      await this.$http.get("/cms/api/media/stat/download-stat/" + this.monthYear, { headers: {Authorization: this.getAuthData().accessToken }}).then(async r => {
        for (var i = 0; i < r.data.length; i++) {
          // console.log(typeof r.data[i].media);

          if (typeof r.data[i].media === "string") {
            await this.$http.get("/api/media/get/" + r.data[i].media + "/" + this.$root.lang.IANA, { headers: { Authorization: this.getAuthData().accessToken }}).then(r2 => { 
              r.data[i].media = r2.data;
            });
          }
        }
        this.downloadStat = r.data
      }).catch(e => { this.handleError(e); })
      
      // console.log(this.downloadStat);
    }
  }
}
</script>

<style>

</style>
